<template>
<div class="blogeditor">
    <h1 v-show="!editState">新建博客</h1>
    <h1 v-show="editState">修改博客</h1>
    <el-input class="info" v-model="blog.title" placeholder="标题"></el-input>
    <el-input class="info" v-model="blog.target" placeholder="标签"></el-input>
    <el-input class="info" v-model="blog.abstract" placeholder="简介"></el-input>
    <el-input class="info" v-model="blog.cover" placeholder="封面"></el-input>
    <!--     <div id="demo1"></div> -->
    <!-- <el-button type="primary" @click="getEditorData">清空输入</el-button> -->

    <!-- 文本编辑器 -->
    <div id="main">
        <mavon-editor ref=md @imgAdd="$imgAdd" v-model="blog.content"/>
    </div>
    <div class="form">
        <el-button type="primary" v-show="!editState" @click="addblog(blog)">添加博客</el-button>
        <el-button v-show="editState" type="primary" @click="modifyBlog">修改博客</el-button>
        <el-button type="primary" @click="clearInput">清空输入</el-button>
    </div>
    <div class="uploader">
        <el-upload class="upload-demo" :headers="Headers" :action="uploadurl" :on-success="handle_success" :on-remove="handleRemove" :before-remove="beforeRemove" multiple :file-list="fileList">
            <el-button size="small" type="primary">上传文件</el-button>
        </el-upload>
        <div :v-show="success">{{ url }}</div>
    </div>
     
</div>
</template>

<script>
import {
    getStore
} from "@/utils/storage";
import url from "@/serviceAPI.config.js";
import axios from "axios"

export default {
    data() {
        return {
            value: '',
            editState: false,
            url: "",
            success: false,
            fileList: [],
            Headers: {
                Authorization: "",
                position: "blog"
            },
            uploadurl: url.upload,
            blog: {
                "title": '',
                "abstract": '',
                "target": '',
                "content": '',
                "cover": ''
            }
        };
    },
    methods: {
        getContent: function () {
            // alert(this.editorContent)
            var json = this.editor.txt.getJSON() // 获取 JSON 格式的内容
            var jsonStr = JSON.stringify(json)
            console.log(json)
            console.log(jsonStr)
            console.log(this.editorContent, '----------------------')
        },
        clearInput() {
            this.blog = {}
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        handle_success(res) {
            this.success = true;
            this.url = res.url;
            this.$message.success("文件上传成功");
        },
        addblog(blog) {
            console.log(blog)
            axios({
                    url: url.blog,
                    method: "post",
                    data: {
                        data: blog
                    },
                })
                .then((Response) => {
                    console.log(Response)
                    if (Response.data.code == 100) {
                        alert("插入博客成功");
                    } else {
                        alert("插入博客失败，状态码错误");
                    }
                })
                .catch((error) => {
                    alert("插入博客失败error", error);
                });
        },
        modifyBlog() {
            axios({
                    url: url.blog,
                    method: "put",
                    data: {
                        data: this.blog
                    }
                })
                .then((Response) => {
                    console.log(Response)
                    if (Response.data.code == 100) {
                        this.$store.commit('blogState')
                        alert("修改博客成功");
                    } else {
                        alert("修改博客失败，状态码错误");
                    }
                })
                .catch((error) => {
                    alert("修改博客成功");
                    console.log(error)
                    // alert("修改博客失败error");
                });
        },
        
    },
    created() {
        this.editState = this.$store.state.blogState
        console.log(this.editState)
        if (this.editState) {
            this.blog = this.$route.query.id
        } else {
            this.blog = {}
            this.clearInput()
        }
        this.Headers.Authorization = getStore("token");
    }

}
</script>

<style scoped>
div {
    width: 60vw;
}

.blogeditor {
    padding: 30px;
}

.info {
    display: flex;
    justify-content: flex-start;
    margin: 10px auto;
    width: 60vw;
}

.uploader {
    margin: 20px;
}
</style>
